import { Injectable, inject } from '@angular/core';
import { JsonApiClientService, ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';

import { type MenuModel } from '@big-direkt/utils/shared';
import { firstValueFrom } from 'rxjs';
import { FooterMenuModel } from '../models/footer-menu.model';
import { HeaderMenuModel } from '../models/header-menu.model';
import { FooterMenuTypes, HeaderMenuTypes } from '../models/menu.types';

@Injectable({
    providedIn: 'root',
})
export class NavService {
    private readonly jsonApiClientService = inject(JsonApiClientService);

    public headerMenus?: HeaderMenuModel;
    public footerMenus?: FooterMenuModel;

    public async initHeaderMenus(menus: { name: keyof HeaderMenuModel; type: HeaderMenuTypes }[]): Promise<void> {
        this.headerMenus = (await this.getMenus(menus)) as HeaderMenuModel;
    }

    public async initFooterMenus(menus: { name: keyof FooterMenuModel; type: FooterMenuTypes }[]): Promise<void> {
        this.footerMenus = (await this.getMenus(menus)) as FooterMenuModel;
    }

    private async getMenus(
        menus: { name: keyof FooterMenuModel | keyof HeaderMenuModel; type: FooterMenuTypes | HeaderMenuTypes }[],
    ): Promise<FooterMenuModel | HeaderMenuModel> {
        const promises: Promise<MenuModel>[] = [];

        menus.forEach(menu => {
            promises.push(firstValueFrom(this.jsonApiClientService.load(menu.type, JsonApiResourceTypes.Menu)));
        });

        const allPromises = await Promise.all(promises);
        const resolvedMenus = {} as Record<string, MenuModel>;

        menus.forEach((menu, index) => {
            resolvedMenus[menu.name] = allPromises[index];
        });

        return resolvedMenus;
    }
}
