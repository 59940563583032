<header
    class="bg-default transition-shadow"
    [ngClass]="{
        'shadow-dropdown': scrollPositionY() > 0,
        'md:shadow-none': scrollPositionY() === 0,
    }">
    <div class="hidden bg-alt md:block">
        <div class="container">
            <ng-content select="[secondaryHeaderContent]"></ng-content>
        </div>
    </div>
    <div class="container py-2">
        <ng-content select="[primaryHeaderContent]"></ng-content>
    </div>
    <ng-content></ng-content>
</header>
