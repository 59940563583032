import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UiLinkModule } from '@big-direkt/ui/link';
import { UiPictureModule } from '@big-direkt/ui/picture';
import { type PageTitleModel } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-ui-page-title',
    templateUrl: './page-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, NgClass, UiLinkModule, UiPictureModule],
})
export class PageTitleComponent {
    @HostBinding('class') public classList = 'block print:hidden';

    @Input() public model?: PageTitleModel | null;
    @Input() public headingSize: 'h1' | 'h2' = 'h2';
    @Input() public showArc = true;

    @HostBinding('class.is-home')
    public get isHome(): boolean {
        return !!this.model?.homePageCard;
    }

    @HostBinding('class.has-image')
    public get hasPicture(): boolean {
        return !!this.model?.picture;
    }

    @HostBinding('class.has-title')
    public get hasTitle(): boolean {
        return this.model?.title !== undefined;
    }

    @HostBinding('class.is-empty')
    public get isEmpty(): boolean {
        return !this.isHome && !this.hasPicture && !this.hasTitle;
    }
}
