import { PortalModule } from '@angular/cdk/portal';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MobileWizardComponent, WizardComponent } from '@big-direkt/form/view';
import { JsonApiClientModule } from '@big-direkt/json-api-client';
import { FormHeaderComponent } from '@big-direkt/nav';
import { ParagraphsHolderModule } from '@big-direkt/paragraphs-holder';
import { RestApiClientModule } from '@big-direkt/rest-api-client';
import { UiBreadcrumbComponent } from '@big-direkt/ui/breadcrumb';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiIntroductionComponent } from '@big-direkt/ui/introduction';
import { UiLinkModule } from '@big-direkt/ui/link';
import { UiPageTitleComponent } from '@big-direkt/ui/page-title';
import { UiPictureModule } from '@big-direkt/ui/picture';
import { IconComponent } from '@big-direkt/utils/icons';
import { ComputeInnerHtmlLinksDirective, Nl2brPipe, SanitizePipe } from '@big-direkt/utils/shared';
import { ContentComponent } from './content.component';

@NgModule({
    declarations: [ContentComponent],
    exports: [ContentComponent],
    imports: [
        ComputeInnerHtmlLinksDirective,
        FormHeaderComponent,
        IconComponent,
        JsonApiClientModule,
        MobileWizardComponent,
        NgClass,
        NgTemplateOutlet,
        Nl2brPipe,
        ParagraphsHolderModule,
        PortalModule,
        RestApiClientModule,
        RouterModule,
        SanitizePipe,
        UiBreadcrumbComponent,
        UiButtonComponent,
        UiIntroductionComponent,
        UiLinkModule,
        UiPageTitleComponent,
        UiPictureModule,
        WizardComponent,
    ],
})
export class ContentModule {}
