import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, SecurityContext } from '@angular/core';
import { Nl2brPipe, SanitizePipe } from '@big-direkt/utils/shared';
import { IntroductionModel } from '../../models/introduction.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'big-ui-introduction',
    templateUrl: './introduction.component.html',
    imports: [SanitizePipe, Nl2brPipe, DatePipe],
    standalone: true,
})
export class IntroductionComponent {
    public readonly securityContext: SecurityContext = SecurityContext.HTML;

    @HostBinding('class') public class = 'block';

    @Input({ required: true }) public model!: IntroductionModel;
    @Input() public textCentered = false;
}
