import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, inject, type ElementRef, type TemplateRef } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { DarkmodeRepository, type ColorTheme } from '@big-direkt/utils/environment';
import { NgOverlayContainerService, type NgPopoverRef } from 'ng-overlay-container';

@Component({
    selector: 'big-ui-darkmode-switch',
    templateUrl: './darkmode-switch.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [A11yModule, MatMenuModule],
})
export class DarkmodeSwitchComponent {
    private readonly darkmodeRepo = inject(DarkmodeRepository);
    private readonly overlayContainerService = inject(NgOverlayContainerService);

    private popoverRef?: NgPopoverRef;

    public openMenu(origin: HTMLElement, content: TemplateRef<ElementRef>): void {
        this.popoverRef = this.overlayContainerService.open({
            content,
            origin,
            configuration: {
                offsetY: 15,
                isResizable: false,
            },
        });
    }

    public selectMode(mode: ColorTheme): void {
        this.darkmodeRepo.setMode(mode);
        this.popoverRef?.close();
    }
}
