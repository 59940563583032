import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchFormComponent } from '@big-direkt/search';
import { UiLinkModule } from '@big-direkt/ui/link';
import { IconComponent } from '@big-direkt/utils/icons';
import { HrefLinkDirective } from '@big-direkt/utils/shared';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MainNavLinkDirective } from './directives/main-nav-link.directive';

@NgModule({
    declarations: [MainNavLinkDirective],
    imports: [
        AsyncPipe,
        HrefLinkDirective,
        IconComponent,
        NgbDropdownModule,
        NgbNavModule,
        NgClass,
        NgTemplateOutlet,
        RouterModule,
        SearchFormComponent,
        UiLinkModule,
    ],
})
export class NavModule {}
