import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UiRepository } from '@big-direkt/state/ui';
import { ScrollService, WindowService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';
import { TabNavService } from './tab-nav.service';

@Component({
    selector: 'big-a11y-tab-nav',
    standalone: true,
    imports: [AsyncPipe, NgClass, TranslocoDirective],
    templateUrl: './tab-nav.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [provideTranslationScope('a11yTabNav', /* istanbul ignore next */ async (lang: string, root: string) => import(`./${root}/${lang}.json`))],
})
export class TabNavComponent {
    private readonly windowService = inject(WindowService);
    private readonly uiRepository = inject(UiRepository);
    private readonly scrollService = inject(ScrollService);
    public readonly a11yTabNavService = inject(TabNavService);

    public readonly isTopBarShown = this.uiRepository.isTopBarShown;

    // I tried rendering this as <a href='#content'> but it navigated to home always. therefore I used a button with custom handling
    public focusItem(key: string): void {
        (this.windowService.document().activeElement as HTMLDivElement).blur();

        const scrollSelector = `#${key}`;
        this.scrollService.scroll(scrollSelector);
    }
}
