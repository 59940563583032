import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';
import { BreakpointService } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-ui-header',
    standalone: true,
    imports: [NgClass],
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    private readonly breakpointService = inject(BreakpointService);

    public readonly scrollPositionY = this.breakpointService.scrollPositionY();

    @HostBinding('class') @Input() public classList = 'print:hidden';
}
