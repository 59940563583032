<button
    [attr.id]="key"
    class="relative font-bold"
    [ngClass]="{ 'opacity-30': isOtherOpen() }"
    role="button"
    [attr.aria-label]="isOpen() ? closeLabel : label"
    (click)="toggleMobileNav()">
    <big-icon
        class="[&>svg]:!w-full w-8 [&>svg]:!h-auto fill-primary dark:fill-dark-primary align-middle"
        [ngClass]="{ '!w-6 m-1': isOpen() }"
        [icon]="isOpen() ? iconClose : icon" />
    <ng-content />
</button>
