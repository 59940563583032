<ng-template
    #quickNavItem
    let-item="item"
    let-icon="icon">
    <div class="flex items-center gap-2 text-primary dark:!text-white dark:hover:!text-primary-light hover:!text-primary-light">
        @if (item.icon) {
            <big-icon
                [icon]="icon"
                class="fill-primary dark:fill-white [&>svg]:!w-5 [&>svg]:!h-auto" />
        }
        <span>{{ item.title }}</span>
    </div>
</ng-template>

<ng-template #mainNav>
    <div class="px-6 py-4">
        <ul>
            @for (item of navService.headerMenus?.main.items; track $index) {
                <li>
                    <button
                        (click)="openSubnav(item)"
                        class="flex justify-between items-center w-full px-0 py-6 mr-0 border-b font-ci text-primary hover:!text-primary-light dark:!text-white dark:hover:!text-primary-light [&_svg]:fill-primary hover:[&_svg]:!fill-primary-light dark:[&_svg]:!fill-white dark:hover:[&_svg]:!fill-primary-light">
                        {{ item.title }}
                        <big-icon [icon]="iconAngleRight" />
                    </button>
                </li>
            }
        </ul>

        <big-nav-quicknav
            wrapperClass="justify-center flex-wrap"
            [itemTemplate]="quickNavItem"
            class="block py-4 md:hidden"
            [menu]="navService.headerMenus?.tools"
            (itemClicked)="sidenavService.closeSidenav()" />
    </div>
</ng-template>

<ng-template #subMenu>
    <div class="px-6 py-4">
        <button
            *transloco="let t; prefix: 'nav'"
            class="flex gap-6 items-center w-full px-0 py-6 mr-0 border-b font-ci text-primary hover:!text-primary-light dark:!text-white dark:hover:!text-primary-light [&_svg]:fill-primary hover:[&_svg]:!fill-primary-light dark:[&_svg]:!fill-white dark:hover:[&_svg]:!fill-primary-light"
            (click)="closeSubnav()">
            <big-icon [icon]="iconAngleLeft" />
            {{ t('back') }}
        </button>
        <h5 class="my-6">{{ subnavMenu()?.title }}</h5>
        <ul class="pb-4">
            @for (sub of subnavMenu()?.children; track $index) {
                <li>
                    <big-ui-link
                        (click)="onNavItemSelected()"
                        linkClass="no-underline"
                        [href]="sub.uri">
                        <div class="py-3.5">
                            {{ sub.title }}
                        </div>
                    </big-ui-link>
                </li>
            }
        </ul>
    </div>
</ng-template>

<mat-sidenav-container>
    <mat-sidenav-content>
        <mat-sidenav-container>
            <mat-sidenav-content>
                <ng-content />
            </mat-sidenav-content>
            <mat-sidenav
                #drawer
                id="sidenav-drawer"
                fixedInViewport="true"
                [fixedTopGap]="topGap()"
                position="end"
                mode="over"
                (closedStart)="closeSubnav()"
                [opened]="isOpened()">
                @switch (sidenavService.currentSidenav()) {
                    @case ('nav') {
                        <ng-container [ngTemplateOutlet]="mainNav" />
                    }
                    @case ('search') {
                        <big-search-form
                            class="px-6 py-4"
                            [location]="'side-nav'"
                            [clearOnSubmit]="true"
                            (submitted)="sidenavService.closeSidenav()" />
                    }
                    @case ('user') {
                        <big-nav-user />
                    }
                }
            </mat-sidenav>
        </mat-sidenav-container>
    </mat-sidenav-content>
    <mat-sidenav
        #subNav
        id="subnav-drawer"
        fixedInViewport="true"
        [fixedTopGap]="topGap()"
        position="end"
        mode="over"
        [opened]="subnavOpened()">
        <ng-container [ngTemplateOutlet]="subMenu" />
    </mat-sidenav>
</mat-sidenav-container>
