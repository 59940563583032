import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, computed, inject } from '@angular/core';
import { BigIcon, IconBigBoldSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { NavType, SidenavService } from '../../services/sidenav.service';

@Component({
    selector: 'big-nav-mobile-icon-button',
    templateUrl: './nav-mobile-icon-button.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent, NgClass],
})
export class NavMobileIconButtonComponent {
    private readonly sidenavService = inject(SidenavService);
    public readonly iconClose = IconBigBoldSchliessen;

    @Input() public key?: string;
    @Input({ required: true }) public navType!: NavType;
    @Input({ required: true }) public label!: string;
    @Input({ required: true }) public closeLabel!: string;
    @Input({ required: true }) public icon!: BigIcon;

    public isOpen = computed(() => this.sidenavService.currentSidenav() === this.navType);
    public isOtherOpen = computed(() => !!this.sidenavService.currentSidenav() && this.sidenavService.currentSidenav() !== this.navType);

    public toggleMobileNav(): void {
        if (this.sidenavService.currentSidenav() !== this.navType) {
            this.sidenavService.openSidenav(this.navType);
        } else {
            this.sidenavService.closeSidenav();
        }
    }
}
