<button
    #darkModeBtn
    type="button"
    class="block outline-offset-[5px]"
    id="dark-mode-button"
    (click)="openMenu(darkModeBtn, menu)">
    <ng-content />
</button>

<ng-template #menu>
    <div
        class="flex flex-col items-start gap-3 p-4"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true">
        <button
            class="hover:!text-primary-light"
            (click)="selectMode('dark')">
            Dark Mode
        </button>
        <button
            class="hover:!text-primary-light"
            (click)="selectMode('light')">
            Light Mode
        </button>
        <button
            class="hover:!text-primary-light"
            (click)="selectMode('system')">
            System
        </button>
    </div>
</ng-template>
