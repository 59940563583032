import { DestroyRef, inject, Injectable, signal, type Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

export type NavType = 'nav' | 'search' | 'user' | undefined;

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly _currentSidenav = signal<NavType>(undefined);

    public get currentSidenav(): Signal<NavType> {
        return this._currentSidenav.asReadonly();
    }

    public constructor() {
        this.router.events
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(e => e instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.closeSidenav();
            });
    }

    public openSidenav(content: NavType): void {
        this._currentSidenav.set(content);
    }

    public closeSidenav(): void {
        this._currentSidenav.set(undefined);
    }
}
