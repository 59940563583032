import { Pipe, type PipeTransform } from '@angular/core';
import { type MenuItemModel } from '@big-direkt/utils/shared';

@Pipe({
    name: 'navChildBreakpointSort',
    standalone: true,
})
export class NavChildrenBreakpointSortPipe implements PipeTransform {
    public transform(value: MenuItemModel[] | undefined, minLength?: number, side?: 'left' | 'right'): MenuItemModel[] {
        if (!Array.isArray(value)) {
            return [];
        }

        if (!side || minLength !== undefined && value.length <= minLength) {
            return value;
        }

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const firstHalf: MenuItemModel[] = value.slice(0, Math.ceil(value.length / 2));
        const secondHalf: MenuItemModel[] = value.slice(firstHalf.length);

        if (side === 'right') {
            return secondHalf;
        }

        return firstHalf;
    }
}
