import { Injectable } from '@angular/core';
import { type TabNavLink } from './tab-nav-link.model';

@Injectable({
    providedIn: 'root',
})
export class TabNavService {
    public readonly navLinks: TabNavLink[] = [];

    public registerLinks(...links: TabNavLink[]): void {
        this.navLinks.push(...links);
        this.navLinks.sort((a, b) => a.order - b.order);
    }
}
